@import "~antd/dist/antd.dark.less"; // Introduce the official dark less style entry file

@body-background: #0A0A0A;

h3 {
  margin: 0px;
  font-weight: normal;
}

.ant-card {
  border: none;
  border-radius: 12px;
  background: none;

  .ant-card-head {
    border: none;
  }
}

.ant-btn {
  border-radius: 8px;
}

.ant-btn-primary {
  background: #0C82FF;
}

.ant-divider {
  border: 0.5px solid #c4c4c4;
}

.ant-dropdown-menu {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);

  .ant-dropdown-menu-item-active {
    color: initial;
  }
}
.ant-modal {
  background: #3a3a3a;
  border: 1px solid #808080;
  border-radius: 16px;
  overflow: hidden;
  padding: 0;
  min-width: 360px;
  max-width: 448px;
  width: auto;

  .ant-modal-header {
    background: #3a3a3a;
    border-bottom: none;
  }

  .ant-modal-body {
    background: #3a3a3a;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 200px;
    padding: 16px;
  }

  .ant-modal-footer {
    background: #3a3a3a;
    border: none;
  }

  .ant-result {
    display: flex;
    flex-direction: column;
  }

  .ant-result-title {
    order: 1;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .ant-result-icon {
    order: 2;

    svg {
      height: 32px;
    }
  }
}

.ant-btn-link {
  color: #0C82FF;
  &:focus, &:hover {
    color: #0C82FF !important; 
  }
}

.ant-table {
  background: none !important;

  .ant-table-cell {
    background: none !important;
  }
}

.action-input {
  color: #ffffff;
  font-size: 40px;
  max-width: 250px;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 768px) {
  .ant-modal {
    margin: 0;
    max-width: 100vw;
    min-width: 100vw; // overrides default
    box-shadow: none;
    position: fixed;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    top: 80px;
    .ant-modal-content {
      height: calc(100vh - 80px);
      box-shadow: none;
      .ant-modal-body {
        height: 100%;
        padding-top: 0;
      }
    }
  }
}

